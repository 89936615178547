import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Avatar, ListSubheader } from "@mui/material";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

import {
  AddCircleOutline,
  AddCircleRounded,
  ArchiveRounded,
  ArrowLeftOutlined,
  Assignment,
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  AssignmentTurnedInRounded,
  DashboardOutlined,
  DashboardRounded,
  DescriptionOutlined,
  DescriptionRounded,
  GroupsOutlined,
  GroupsRounded,
  Logout,
  NoteAddOutlined,
  NoteAddRounded,
  PersonAddOutlined,
  PersonAddRounded,
  PersonRounded,
  PrintOutlined,
  PrintRounded,
  Settings,
} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import { useAuthContext } from "../../Providers/AuthProvider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useFormBuilderContext } from "../../Providers/FormBuilderProvider";
import { useProfileContext } from "../../Providers/ProfileProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import {
  faArrowAltCircleLeft,
  faArrowLeft,
  faBars,
  faClose,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  CustomMenu,
  CustomMenuItem,
  CustomMenuIcon,
} from "../../Elements/CustomMenu";
import { CustomListItem } from "../../Elements/CustomList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  height: "60px",
  justifyContent: "center",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  height: "59px",
  justifyContent: "flex-end",
}));

const Dashboard = () => {
  const { setMode, setFormId } = useFormBuilderContext();
  const { profile } = useProfileContext();
  const theme = useTheme();
  const { logOut } = useAuthContext();
  const [profileAnchor, setProfileAnchor] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [profileMenu, setProfileMenu] = React.useState(false);
  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };
  const navigate = useNavigate();
  const handleProfileMenuClose = () => {
    setProfileMenu(false);
  };
  const location = useLocation();

  const iconStyles = {
    // color: "#17236E",
    color: "#7a7979",

    width: "25px",
    height: "25px",
  };

  const highlightIconStyles = {
    color: "#57bbef", // Highlight color for some icons
    width: "25px",
    height: "25px",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar elevation={1} variant="outlined" position="fixed" open={open}>
        <Toolbar
          style={{
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <IconButton
              sx={{
                borderRadius: "3px",
                padding: "0.5em",
              }}
              color="inherit"
              aria-label="toggle drawer"
              onClick={toggleDrawer}
              edge="start"
            >
              {open ? (
                <FontAwesomeIcon color="black" icon={faClose} />
              ) : (
                <FontAwesomeIcon color="black" icon={faBars} />
              )}
            </IconButton>
             <img
            src={"/assets/logo/veridocs-v2.jpeg"}
            style={{
              height: "40px",
            }}
            alt="logo"
          />  
          </Box>

          <div style={{ width: "100px" }}>
            <IconButton
              onClick={(event) => {
                setProfileAnchor(event.target);
                setProfileMenu((prev) => !prev);
              }}
              sx={{
                height: "2em",
                width: "2em",
                "&:hover": {
                  // Remove the hover effect by setting it to 'none'
                  bgcolor: "transparent",
                },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "#57bbef",

                  height: "2em",
                  width: "2em",
                }}
              >
                {String(profile.agency_name).at(0).toUpperCase()}
              </Avatar>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  marginLeft: "15px",
                  marginRight: "6px",
                }}
              >
                {profile?.agency_name}
              </Typography>
              <ArrowDropDownIcon />
            </IconButton>

            <CustomMenu
              elevation={3}
              anchorEl={profileAnchor}
              open={profileMenu}
              onClose={handleProfileMenuClose}
              onClick={handleProfileMenuClose}
            >
              {/* <Box
                p={2.2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    height: "53px",
                    width: "53px",
                    backgroundColor: "#1260cc",
                  }}
                >
                  {String(profile.agency_name).at(0).toUpperCase()}
                </Avatar>
                {/* <Typography
                  pl={2}
                  fontFamily="Playfair"
                  fontWeight="bold"
                  fontSize="19px"
                >
                  {profile?.agency_name}
                </Typography> 
              </Box> */}

              <CustomMenuItem>
                <CustomMenuIcon>
                  <PersonRounded
                    sx={{
                      color: "black",
                    }}
                  />
                </CustomMenuIcon>
                View Profile
              </CustomMenuItem>
              <CustomMenuItem>
                <CustomMenuIcon>
                  <Settings />
                </CustomMenuIcon>
                Settings
              </CustomMenuItem>
              <Divider />
              <CustomMenuItem
                onClick={async () => {
                  await logOut();
                  navigate("/");
                }}
              >
                <CustomMenuIcon>
                  <Logout />
                </CustomMenuIcon>
                Log Out
              </CustomMenuItem>
            </CustomMenu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        ></DrawerHeader>
        <Divider />
        <List sx={{ margin: "0 0.4em" }}>
          <CustomListItem
            sx={{ padding: "10px 0" }} // Adds padding inside the CustomListItem
            primary={
              <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                Summary
              </Typography>
            }
            highlightIcon={
              <SpaceDashboardIcon fontSize="small" sx={highlightIconStyles} />
            }
            normalIcon={<SpaceDashboardIcon fontSize="small" sx={iconStyles} />}
            highlight={location.pathname.includes("summary")}
            button
            onClick={() => {
              navigate("/dashboard/summary");
            }}
          />
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              sx={{ padding: "10px 0" }} // Adds padding inside the CustomListItem
              button
              highlight={location.pathname.includes("forms")}
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Forms
                </Typography>
              }
              highlightIcon={
                <DescriptionRounded sx={highlightIconStyles} fontSize="small" />
              }
              normalIcon={
                <DescriptionRounded sx={iconStyles} fontSize="small" />
              }
              onClick={() => {
                navigate("/dashboard/forms", { state: { mode: "create" } });
              }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              button
              highlight={location.pathname.includes("assignments")}
              onClick={() => {
                navigate("/dashboard/assignments");
              }}
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Assignments
                </Typography>
              }
              highlightIcon={
                <Assignment fontSize="small" sx={highlightIconStyles} />
              }
              normalIcon={<Assignment sx={iconStyles} fontSize="small" />}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              highlight={location.pathname.includes("formBuilder")}
              button
              onClick={() => {
                setFormId(null);
                setMode("create");
                navigate("/dashboard/formBuilderPage", {
                  state: { mode: "create" },
                });
              }}
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Form Builder
                </Typography>
              }
              normalIcon={<NoteAddRounded fontSize="small" sx={iconStyles} />}
              highlightIcon={
                <NoteAddRounded fontSize="small" sx={highlightIconStyles} />
              }
            />
          </Box>
          <Divider sx={{ marginTop: "15px", marginBottom: "5px" }} />
          <ListSubheader color="black" sx={{ fontSize: "15px" }}>
            Documents
          </ListSubheader>
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              button
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Assign
                </Typography>
              }
              normalIcon={<AddCircleRounded fontSize="small" sx={iconStyles} />}
              highlightIcon={
                <AddCircleRounded fontSize="small" sx={highlightIconStyles} />
              }
              highlight={location.pathname.includes("assignment/create")}
              onClick={() => {
                navigate("/dashboard/assignment/create");
              }}
            >
              <ListItemText primary="Assign" />
            </CustomListItem>
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              button
              highlight={location.pathname.includes("assignment/verify")}
              normalIcon={
                <AssignmentTurnedInRounded
                  fontSize="small"
                  sx={iconStyles}
                  primary="Verify"
                />
              }
              highlightIcon={
                <AssignmentTurnedInRounded
                  sx={highlightIconStyles}
                  fontSize="small"
                  primary="Verify"
                />
              }
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Verify
                </Typography>
              }
              onClick={() => {
                navigate("/dashboard/assignment/verify");
              }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              sx={{ padding: "10px 0" }} // Adds padding inside the CustomListItem
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Report
                </Typography>
              }
              highlightIcon={
                <PrintRounded fontSize="small" sx={highlightIconStyles} />
              }
              normalIcon={<PrintRounded fontSize="small" sx={iconStyles} />}
              button
              highlight={location.pathname.includes("assignment/result")}
              onClick={() => {
                navigate("/dashboard/assignment/result");
              }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <CustomListItem
              primary={
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  Archive
                </Typography>
              }
              button
              highlight={location.pathname.includes("assignment/archieve")}
              normalIcon={<ArchiveRounded fontSize="small" sx={iconStyles} />}
              highlightIcon={
                <ArchiveRounded fontSize="small" sx={highlightIconStyles} />
              }
              onClick={() => {
                navigate("/dashboard/assignment/archieve");
              }}
            />
          </Box>

          <Divider sx={{ marginTop: "10px", marginBottom: "5px" }} />

          <ListSubheader sx={{ fontSize: "15px" }}>
            Field Verifiers
          </ListSubheader>
          <CustomListItem
            highlight={location.pathname.includes("fieldVerifierPage")}
            normalIcon={<GroupsRounded fontSize="small" sx={iconStyles} />}
            highlightIcon={
              <GroupsRounded sx={highlightIconStyles} fontSize="small" />
            }
            button
            primary={
              <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                Verifiers List
              </Typography>
            }
            onClick={() => {
              navigate("/dashboard/fieldVerifierPage", {
                state: { mode: "view" },
              });
            }}
          />
          <CustomListItem
            button
            primary={
              <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                Add Verifiers
              </Typography>
            }
            highlight={location.pathname.includes("addFieldVerifierPage")}
            normalIcon={<PersonAddRounded fontSize="small" sx={iconStyles} />}
            highlightIcon={
              <PersonAddRounded sx={highlightIconStyles} fontSize="small" />
            }
            onClick={() => {
              navigate("/dashboard/addFieldVerifierPage");
            }}
          />
        </List>
      </Drawer>
      <Main open={open} sx={{ backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
        <div
          style={{
            marginTop: "4em",
          }}
        ></div>
        <Box>
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
};

export default Dashboard;
