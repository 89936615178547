import { PersonRounded } from "@mui/icons-material";
import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { personValidation } from "../../Models/PersonDetails";
import { useForm } from "../../Utils/FormValidator";
import { StyledTextField } from "../../Elements/CustomTextField/CustomTextField";

const PersonDetailsForm = forwardRef((props, ref) => {
  const [data, setData, validate, errors] = useForm(personValidation);
  const [phoneError, setPhoneError] = useState("");

  // Phone number validation function
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[6-9]\d{0,9}$/; // Allow up to 10 digits for input validation
    return phoneRegex.test(phoneNumber) || phoneNumber === ""; // Allow empty input
  };

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return validate();
    },
    getData: () => data,
  }));

  const handlePhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;
    if (validatePhoneNumber(phoneNumber) || phoneNumber === "") {
      setData((prev) => ({ ...prev, phone: phoneNumber }));
      setPhoneError(""); // Clear error message if input is valid or empty
    } else {
      setPhoneError(
        "Please enter a valid phone number starting with 9, 8, 7 or 6 and should be 10 digits long.",
      );
    }
  };

  const handlePhoneBlur = () => {
    setPhoneError(""); // Clear error message on blur
  };

  return (
    <Grid item xs={12} p="0.5em" md={6}>
      <Paper
        variant="outlined"
        sx={{
          p: "1em 2em 2em 2em",
          minWidth: "300px",
          margin: "0 auto",
        }}
      >
        <Typography
          fontFamily="Playfair Display, serif"
          fontSize="23px"
          sx={{ display: "flex", alignItems: "end" ,}}
          fontWeight={"regular"}
        >
          <PersonRounded
            sx={{
              color: "#17236e",
              mb: "0.27em",
              mr: "1em",
              transform: "scale(1.3)",
            }}
          />
          {props.person} Details
        </Typography>
        <Divider sx={{mt:1}}/>

        <Grid container sx={{mt:2}}>
          <Grid item xs={6} pr="1em">
            <StyledTextField
              label="First Name"
              error={errors?.fName}
              value={data.fName}
              onChange={(event) => {
                setData((prev) => ({ ...prev, fName: event.target.value }));
              }}
            />
          </Grid>
          <Grid item xs={6} pl="1em">
            <StyledTextField
              label="Last Name"
              error={errors.lName}
              value={data.lName}
              onChange={(event) => {
                setData((prev) => ({ ...prev, lName: event.target.value }));
              }}
            />
          </Grid>
          <Grid item xs={6} pr="1em">
            <StyledTextField
              error={!!phoneError || errors?.phone}
              label="Phone Number"
              value={data.phone}
              onChange={handlePhoneNumberChange}
              onBlur={handlePhoneBlur} // Clear error message on blur
            />
            {!!phoneError && (
              <Typography variant="caption" color="error">
                {phoneError}
              </Typography>
            )}
            {errors?.phone && (
              <Typography variant="caption" color="error">
                Invalid phone number
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
});

export default PersonDetailsForm;
