import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import "./HomepageContent.css";
import { useNavigate } from "react-router-dom";

const HomepageContent = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div
        className="header-area header-sticky wow slideInDown fixed-header"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <a href="index.html" className="logo">
                  <img src="images/logo.png" alt="Chain App Dev" />
                </a>

                <ul className="nav">
                  <li className="scroll-to-section">
                    <a href="#top" className="active">
                      Home
                    </a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#about">About VeriDocs</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#services">Features</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#pricing">Pricing</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#newsletter">Contacts</a>
                  </li>
                  <li>
                    <div className="gradient-button">
                      <a
                        id="modal_trigger"
                        href=""
                        onClick={()=>{
                          navigate("/login")
                        }}
                      >
                       Log in  <i className="fa fa-sign-in-alt"></i> 
                      </a>
                    </div>
                  </li>
                  <li className="scroll-to-section" style={{marginTop:"-8px"}} >
                    <a href="https://api.whatsapp.com/send?phone=9836474066&text=Hello VeriDocs">
                  <img src="images/whatsapp.png" alt="whatsapp-imge" width={60} height={60} />                  
                  </a>
                  </li>
                  
                </ul>
                <a className="menu-trigger">
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Log In
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <FacebookLoginButton />
            </ListItem>
            <ListItem>
              <GoogleLoginButton />
            </ListItem>
            <p style={{ textAlign: "center", marginTop: "10px" }}>
              Or Use Email Address
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <ListItem>
                <Button
                  onClick={() => window.open("/login", "_blank")}
                  variant="contained"
                  style={{ padding: "10px" }}
                >
                  Login
                </Button>
              </ListItem>
              <ListItem>
                <Button variant="contained" style={{ padding: "10px" }}>
                  Sign Up
                </Button>
              </ListItem>
            </div>
          </List>
        </DialogContent>
      </Dialog>

      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div
                    className="left-content show-up header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <h2>
                          Automotive Field Verification, Efficient Auditing &
                          Reporting
                        </h2>
                        <p>
                          <b>
                            Improving collaboration between Field Executives &
                            Agencies to increase the productivity in field
                            verification, claim, settlement and background
                            verification processes.
                          </b>
                        </p>
                      </div>
                      <div className="col-lg-12">
                        {/* <div className="white-button first-button scroll-to-section">
                          <a href="#contact">
                            Trial Download<i className="fab fa-apple"></i>
                          </a>
                        </div> */}
                        <div className="white-button scroll-to-section">
                          <a href="https://play.google.com/store/apps/details?id=com.reignsys.veridocs&hl=en_IN" className="flex">
                           <span>Download Andriod App</span> <i className="fab fa-google-play"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <img src="images/slider-dec.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="section-heading">
                <h4>
                  About <em>VeriDocs</em>
                </h4>
                <img src="images/heading-line-dec.png" alt="" />
                <p>
                  VeriDocs is a powerful smartly designed and developed solution
                  to manage the organization’s Field verification process. It
                  helps the companies to manage their customer’s Field
                  verification process along with their field
                  verifier/representatives efficiently.
                  <br />
                  It is suitable for the companies who are involved in Field
                  verification process in any industries like Banking, Finance,
                  and Insurance. It helps the company to automate the
                  verification process and field verifier's visits to the end
                  customer and respective process involved.
                </p>
              </div>
              <div className="gradient-button">
                <a href="#">&nbsp;&nbsp;&nbsp;Why VeriDocs?&nbsp;&nbsp;</a>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Physical 2 Digital</a>
                    </h4>
                    <p>
                      Capture of data in a digital format & less paperwork for
                      field executives with Digital smart forms, transforms the
                      Physical work to Digital.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Customizable</a>
                    </h4>
                    <p>
                      VeriDocs is almost 100% customizable as per your need with
                      simple and easy to use UI which provides seamless user
                      experience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Secure & Cloud-Based</a>
                    </h4>
                    <p>
                      Cloud-based infrastructure provides End-to-End encryption
                      and increases your data security.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box-item">
                    <h4>
                      <a href="#">Improved Decision Making</a>
                    </h4>
                    <p>
                      With real time data and reduced verification errors, you
                      can make efficient & Improved decision making.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right-image">
                <img src="images/about-right-dec.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="clients" className="the-clients">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  Know What <em>VeriDocs</em> is trying to solve?
                </h4>
                <img src="images/heading-line-dec.png" alt="" />
                <p>
                  Field & Background verification is an important aspect of many
                  industries to check the authenticity of a person or a company.
                  Inefficient management of these processes leads to a drop in
                  productivity and therefore profitability. VeriDocs helps to
                  increase Productivity & Profitibility.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div
                className="section-heading  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h4>
                  Amazing <em>Features &amp; Services</em> for you
                </h4>
                <img src="images/heading-line-dec.png" alt="" />
                <p>
                  VeriDocs is a bundle of multiple features, suitable for{" "}
                  <a href="#" target="_parent">
                    Banking, Financial, and Insurance sectors
                  </a>{" "}
                  Some of its features are:
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="service-item first-service">
                <div className="icon"></div>
                <h4>Easy Collaboration</h4>
                <p>
                  Effective and Easy collaboration between Field Representatives
                  and Back-Office makes approval process easy and fast.
                </p>
                <div className="text-button">
                  <a href="#">
                    Know more <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item second-service">
                <div className="icon"></div>
                <h4>Simple Task View</h4>
                <p>
                  VeriDocs provides your field representatives a simple view of
                  assigned tasks and help them plan their works more
                  efficiently.
                </p>
                <div className="text-button">
                  <a href="#newsletter">
                    Know more <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item third-service">
                <div className="icon"></div>
                <h4>Automated Workflow</h4>
                <p>
                  Automated Workflows makes most of field representatives and
                  agency works easy, and hence auditing process is easy and
                  takes less time.
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="service-item fourth-service">
                <div className="icon"></div>
                <h4>Real-Time Insights</h4>
                <p>
                  You can get access to most of the information and reports from
                  the field representatives in one place with VeriDocs
                  application.
                </p>
                <div className="text-button">
                  <a href="#">
                    Read More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing" className="pricing-tables">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  We offer comparatively the best <em>Prices</em> that you can
                  get from others.
                </h4>
                <img src="images/heading-line-dec.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-item-regular">
                <span className="price">$</span>
                <h4>Starter Plan</h4>
                <div className="icon">
                  <img src="images/pricing-table-01.png" alt="" />
                </div>
                <ul>
                  <li>Up to 5 users/month</li>
                  <li>Limited Support</li>
                </ul>
                <div className="border-button">
                  <a href="#newsletter">Purchase This Plan Now</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-item-pro">
                <span className="price">$$</span>
                <h4>Business Plan</h4>
                <div className="icon">
                  <img src="images/pricing-table-01.png" alt="" />
                </div>
                <ul>
                  <li>Up to 25 users/month</li>
                  <li>Custom & Secured Forms</li>
                  <li>Premium Support</li>
                </ul>
                <div className="border-button">
                  <a href="#newsletter">Purchase This Plan</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="pricing-item-regular">
                <span className="price">$$$</span>
                <h4>Premium Plan</h4>
                <div className="icon">
                  <img src="images/pricing-table-01.png" alt="" />
                </div>
                <ul>
                  <li>More than 100 users/month</li>
                  <li>24/7 Support</li>
                  <li>Premium Forms & Customization</li>
                  <li>Fastest Network</li>
                </ul>
                <div className="border-button">
                  <a href="#newsletter">Purchase This Plan Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer id="newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-heading">
                <h4>
                  Feel free to Contact Us if you have any query related to
                  VeriDocs [Email: info@veridocs.in]
                  [Call/WhatsApp: +91 98364 74066]
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="copyright-text">
              <p>
                Copyright, VeriDocs. All Rights Reserved.{" "}
                <a href="/privacy">Privacy Policy </a>
                <br />
                Supported By:{" "}
                <a href="reignsys.com" target="_blank" title="Reignsys">
                  Reignsys
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomepageContent;
