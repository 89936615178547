import {
  Box,
  Button,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {} from "@mui/system";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { database } from "../../Firebase/Firebase";
import "./AssignmentDetailsPage.css";

const AssignmentDetailsPage = () => {
  const [assignment, setAssignment] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [historySummary, setHistorySummary] = useState([]);
  const [form, setForm] = useState({});
  const [fv, setFv] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const getForm = async () => {
    const snapshot1 = await getDoc(
      doc(database, `assignments/${id}/form_data/data`)
    );
    const snapshot2 = await getDoc(
      doc(database, `assignments/${id}/form_data/result`)
    );
    setForm({ data: snapshot1.data(), response: snapshot2.data() ?? {} });
  };

  const getFv = async (fvId) => {
    const snapshot = await getDoc(doc(database, "field_verifier", fvId));
    setFv(snapshot.data()?.name);
  };

  useEffect(() => {
    setIsLoading(true);
    const unsubscribe = onSnapshot(
      doc(database, "assignments", id),
      { includeMetadataChanges: true },
      (snapshot) => {
        const data = snapshot.data();
        setAssignment({ ...data, id: snapshot.id });
        getFv(data?.assigned_to);
        getForm();
        setHistorySummary(data?.history || []);
        setIsLoading(false);
      }
    );
    return () => unsubscribe();
  }, [id]);

  const renderStepContent = (history) => {
    return history.map((event, index) => (
      <Step key={index}>
        <StepLabel>
          {event.status}
          <Typography fontSize="12px">{event.date}</Typography>
        </StepLabel>
      </Step>
    ));
  };

  return !isLoading ? (
    <div>
      {/* Stepper Component */}
      <Box sx={{ width: "100%", marginBottom: 2 }}>
        <Stepper activeStep={historySummary.length} alternativeLabel>
          {renderStepContent(historySummary)}
        </Stepper>
      </Box>

    <Box>
      {assignment.persons?.length > 0 && (
        <TableContainer component={Paper} sx={{ marginBottom: 3,marginTop:10}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><Typography fontWeight="bold">First Name</Typography></TableCell>
                <TableCell><Typography fontWeight="bold">Last Name</Typography></TableCell>
                <TableCell><Typography fontWeight="bold">Form Name</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignment.persons.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.details?.fName || "N/A"}</TableCell>
                  <TableCell>{item.details?.lName || "N/A"}</TableCell>
                  <TableCell>{form.data?.name || "No form Chosen"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
</Box>
    
<Grid container spacing={2} sx={{marginTop:5}}>
      {/* Field Verifier Card */}
      <Grid item xs={12} sm={6} md={6}>
        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Box
            sx={{
              padding: 1,
              backgroundColor: "whitesmoke",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            Field Verifier
          </Box>
          <Box
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{fv || "No Field Verifier Assigned"}</span>
            {assignment.assigned_to && (
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/dashboard/fieldVerifier/${assignment.assigned_to}`, {
                    state: { mode: "view" },
                  })
                }
              >
                Details
              </Button>
            )}
          </Box>
        </Paper>
      </Grid>

      {/* Form Card */}
      <Grid item xs={12} sm={6} md={6}>
        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Box
            sx={{
              padding: 1,
              backgroundColor: "whitesmoke",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            Form
          </Box>
          <Box
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{form.data?.name || "No Form Chosen"}</span>
            {form.data?.name && (
              <Button
                variant="contained"
                onClick={() =>
                  navigate("/dashboard/assignment/response/", {
                    state: { id },
                  })
                }
              >
                Details
              </Button>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
    </div>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ClipLoader loading={true} size={30} />
    </Box>
  );
};

export default AssignmentDetailsPage;
